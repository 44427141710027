$desktop: '(max-width: 1440px)'
$laptop: '(max-width: 1024px)'
$tablet: '(max-width: 768px)'
$mobile: '(max-width: 425px)'

$accent: #ff8242
$bg: #f3fafc
$border: #d4e6ed
$invert: #fff
$main: #5bc6ea
$text-main: #4c4c4c
$text-optional: #939b9f

$shadow: 0 0 8px rgba(0, 45, 77, .05), 0 32px 40px rgba(0, 45, 77, .15)

$path: '../../public/static/'
$tr-options: .2s ease-in-out

$light-theme: '&:not(.dark-theme &)'
$dark-theme: '.dark-theme &'
$any-theme: '.dark-theme &, &:not(.dark-theme &)'
