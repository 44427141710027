.input-color
  position: relative
  display: flex
  align-items: center
  width: 67px
  height: 32px
  padding-left: 8px
  border: 1px solid $border
  border-radius: 2px
  transition: border-color $tr-options
  cursor: pointer

  input
    position: absolute
    top: 12px
    left: 0
    visibility: hidden

  &:hover
    @media (hover)
      .core-icon
        fill: $accent

  &_error
    border-color: $accent

  &__color
    width: 16px
    height: 16px

  &__vertical
    width: 1px
    height: 16px
    background: $border
