.icon
  display: block
  flex-shrink: 0
  fill: $main
  transition: fill $tr-options

  #{$dark-theme}
    fill: $invert

  &_light
    fill: #d9d9d9

  &_none
    #{$any-theme}
      fill: none

  &_rotate
    animation: spinLeft 1s infinite linear

  &_stroke
    stroke: $main

    #{$dark-theme}
      stroke: $invert

  &_spinner
    & > path
      transform-origin: center

      &:first-child
        animation: spinLeft 1.5s infinite linear

      &:last-child
        animation: spinRight 1s infinite linear

  @keyframes spinRight
    0%
      transform: rotate(0deg)

    100%
      transform: rotate(360deg)

  @keyframes spinLeft
    0%
      transform: rotate(0deg)

    100%
      transform: rotate(-360deg)
